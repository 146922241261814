/**
 * @file Manages the analytics constants for components.
 * @author kkumari
 */

var componentAnalytics = {
  globalHeader: {
    analyticsType: {
      GLOBAL_NAV: 'global-nav'
    },
    analyticsValue: {
      GLOBAL_NAV_LOGO: 'logo',
      GLOBAL_NAV_PHONE: 'phone',
      GLOBAL_NAV_FAX: 'fax',
      GLOBAL_NAV_EMAIL: 'email',
      GLOBAL_NAV_ADDRESS: 'address',
      GLOBAL_NAV_HOURS: 'hours',
      GLOBAL_NAV_UTILITIES: 'utilities',
      GLOBAL_NAV_CATEGORY_MENU: 'category-menu',
      GLOBAL_NAV_CATEGORY_LINK: 'category-link',
      GLOBAL_NAV_APPOINTMENT: 'appointment',
      GLOBAL_NAV_MOBILE_MENU: 'mobile-menu'
    }
  },
  globalHeaderMasterBrand: {
    analyticsType: {
      GLOBAL_NAV: 'global-nav'
    },
    analyticsValue: {
      GLOBAL_NAV_LOGO: 'logo',
      GLOBAL_NAV_CATEGORY_MENU: 'category-menu',
      GLOBAL_NAV_CATEGORY_LINK: 'category-link',
      GLOBAL_NAV_APPOINTMENT: 'appointment',
      GLOBAL_NAV_MOBILE_MENU: 'mobile-menu'
    }
  },
  sociallinks: {
    analyticsType: {
      SOCIAL_LINK: 'icon-social-feed'
    },
    analyticsValue: {
      SOCIAL_LINK_FACEBOOK: 'Facebook',
      SOCIAL_LINK_YOUTUBE: 'Youtube',
      SOCIAL_LINK_LINKEDIN: 'Linked In',
      SOCIAL_LINK_TWITTER: 'Twitter',
      SOCIAL_LINK_INSTAGRAM: 'Instagram'
    }
  },
  heroCarousel: {
    analyticsType: {
      CTA: 'cta-hero'
    }
  },
  galleryModal: {
    analyticsType: {
      CAROUSEL_ARROWS: 'arrow-gallery'
    },
    analyticsValue: {
      PREV_ARROW: 'previous',
      NEXT_ARROW: 'next'
    }
  },
  imageSlider: {
    analyticsType: {
      ARROWS: 'arrow-image-slider'
    },
    analyticsValue: {
      PREV_ARROW: 'previous',
      NEXT_ARROW: 'next'
    }
  },
  staffBlade: {
    analyticsType: {
      STAFF_CARD: 'card-staff',
      STAFF_BLADE_CTA: 'blade-cta-staff'
    }
  },
  jumpLink: {
    analyticsType: {
      TOP_LINK: 'jump-links'
    }
  },
  gallery: {
    analyticsType: {
      GALLERY_CARD: 'card-gallery'
    }
  },
  miniGallery: {
    analyticsType: {
      SECTION_CTA: 'cta-gallery',
      TILES: 'tile-gallery'
    }
  },
  servicesBlade: {
    analyticsType: {
      CTA_SERVICES_TITLE: 'cta-services-tile'
    }
  },
  locatorMap: {
    analyticsType: {
      CTA_LOCATION: 'address-box'
    }
  },
  locations: {
    analyticsType: {
      CTA_LOCATION: 'location-list'
    }
  },
  contactUs: {
    analyticsType: {
      CONTACT_FORM_TYPE: 'contact-form'
    },
    analyticsValue: {
      CONTACT_FORM_VALUE: 'submit'
    }
  },
  locationFinder: {
    locationFinderFilter: {
      analyticsType: 'location-finder-filter'
    },
    locationFinderCTA: {
      analyticsType: 'location-finder-find',
      analyticsValue: 'submit'
    },
    locationFinderPagination: {
      analyticsType: 'location-finder-page'
    },
    locationFinderVisitLink: {
      analyticsType: 'location-finder-website'
    },
    locationListCTA: {
      analyticsValue: 'submit',
      analyticsType: 'location-finder-find'
    },
    multiLocationsAccordion: {
      analyticsValue: 'address',
      analyticsType: 'accordion-list'
    },
    featuredMessage: {
      analyticsType: 'featured-message'
    }
  },
  multiLocationGrid: {
    address: {
      analyticsValue: 'address',
      analyticsTypeCard: 'multi-location-grid-card',
      analyticsTypeMap: 'multi-location-grid-map'
    }
  },
  multiLocationMap: {
    address: {
      analyticsValue: 'address',
      analyticsType: 'multi-location-infowindow-map'
    }
  },
  accordionBlade: {
    analyticsValue: 'accordion-blade',
    analyticsType: 'accordion-blade-cta'
  },
  bookForm: {
    analyticsType: {
      BOOK_FORM_TYPE: 'book-form'
    },
    analyticsValue: {
      BOOK_FORM_VALUE: 'submit'
    }
  },
  DocumentsAndForms: {
    analyticsType: {
      DOWNLOAD_CTA_TYPE: 'download-cta'
    }
  },
  blogList: {
    analyticsType: {
      BLOG_CTA: 'blog-cta',
      BLOG_FILTER: 'blog-filter'
    }
  },
  nva6040Carousel: {
    analyticsType: {
      CTA_6040: 'cta-50-50'
    }
  },
  nvaContactUs: {
    analyticsType: {
      CONTACT_FORM_TYPE: 'contact-form'
    },
    analyticsValue: {
      CONTACT_FORM_VALUE: 'submit'
    }
  },
  nvaFooter: {
    analyticsType: {
      FOOTER_LOGO_TYPE: 'footer-logo',
      ADDRESS_BOX_TYPE: 'address-box',
      FOOTER_PRIMARY_TYPE: 'footer-primary',
      FOOTER_SECONDARY_TYPE: 'footer-secondary',
      FOOTER_SOCIAL_FEED_TYPE: 'footer-social-feed'
    },
    analyticsValue: {
      FOOTER_LOGO_VALUE: 'footer-logo',
      ADDRESS_BOX_VALUE: 'address-box',
      FOOTER_PRIMARY_VALUE: 'footer-primary',
      FOOTER_SECONDARY_VALUE: 'footer-secondary',
      FOOTER_SOCIAL_FEED_VALUE: 'footer-social-feed'
    }
  },
  globalFooter: {
    analyticsType: {
      GLOBAL_NAV: 'global-nav'
    },
    analyticsValue: {
      GLOBAL_NAV_LOGO: 'footer-logo',
      GLOBAL_NAV_PHONE: 'footer-phone',
      GLOBAL_NAV_ADDRESS: 'footer-address',
      GLOBAL_NAV_FAX: 'footer-fax',
      GLOBAL_NAV_EMAIL: 'footer-email',
      GLOBAL_NAV_MAIN: 'footer-main',
      GLOBAL_NAV_UTILITIES: 'footer-utilities',
      GLOBAL_NAV_CATEGORY_MENU: 'footer-category-menu',
      GLOBAL_NAV_CATEGORY_LINK: 'footer-category-link',
      GLOBAL_NAV_APPOINTMENT: 'footer-appointment',
      GLOBAL_NAV_MOBILE_MENU: 'footer-mobile-menu'
    }
  }
};
export default componentAnalytics;