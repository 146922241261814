import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "ariaLabel", "headingText", "subHeadline", "subHeadingText", "headingType", "headingTypeSubHeading", "descriptionText", "descriptionType", "textLocation", "cta", "styleType", "fontColor", "isHero", "isCurrentSlide", "isAlreadyWraped", "entryId"];
var __jsx = React.createElement;
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import styles from './TextCard.style';
import RichText from '../../atoms/RichText/RichText';
import Para from '../../atoms/Para';
import Heading from '../../atoms/Heading';
import Anchor, { FakeAnchorStyled } from '../../atoms/Anchor';
import { siteConstants } from '../../../constants/globalConstants';
import { ContentfulLivePreview } from '@contentful/live-preview';
var TextCard = function TextCard(_ref) {
  var className = _ref.className,
    ariaLabel = _ref.ariaLabel,
    headingText = _ref.headingText,
    subHeadline = _ref.subHeadline,
    subHeadingText = _ref.subHeadingText,
    headingType = _ref.headingType,
    headingTypeSubHeading = _ref.headingTypeSubHeading,
    descriptionText = _ref.descriptionText,
    descriptionType = _ref.descriptionType,
    textLocation = _ref.textLocation,
    cta = _ref.cta,
    styleType = _ref.styleType,
    fontColor = _ref.fontColor,
    isHero = _ref.isHero,
    isCurrentSlide = _ref.isCurrentSlide,
    isAlreadyWraped = _ref.isAlreadyWraped,
    entryId = _ref.entryId,
    others = _objectWithoutProperties(_ref, _excluded);
  var multiLineHeadline;
  var multiLineSubHeadline;
  var _useState = useState(isHero && !isCurrentSlide ? false : true),
    shouldBeTabbable = _useState[0],
    setShouldBeTabbable = _useState[1];
  var TextCardContainer = useRef(null);
  useEffect(function () {
    var timer;
    if (isHero && TextCardContainer) {
      var newWidth = window.innerWidth;
      // makes all anchors in hero temporarely untabbable while slide is changing
      setShouldBeTabbable(false);
      // the dealy is to give time for slide to finish (the slider takes 1000 ms so I made this 1010)
      var delayInMilliseconds = 1010;
      timer = setTimeout(function () {
        var textCardBoundaries = TextCardContainer.current.getBoundingClientRect();
        setShouldBeTabbable(isCurrentSlide && textCardBoundaries.width > 0 && textCardBoundaries.left >= 0 && textCardBoundaries.right <= newWidth);
      }, delayInMilliseconds);
    }
    return function () {
      clearTimeout(timer);
    };
  }, [TextCardContainer, isCurrentSlide]);
  if (isHero) {
    // the regex is to prevent a user from hitting enter twice and
    // enging up with two </br> instead of one
    if (headingText && headingText.includes('\n')) {
      var headingTextAsArray = headingText.trim().replace(/\n+/g, "\n").split('\n');
      multiLineHeadline = headingTextAsArray.map(function (item, index) {
        return index === headingTextAsArray.length - 1 ? item : __jsx(React.Fragment, null, item, __jsx("br", null));
      });
    }
    if (subHeadingText && subHeadingText.includes('\n')) {
      var subHeadingTextAsArray = subHeadingText.trim().replace(/\n+/g, "\n").split('\n');
      multiLineSubHeadline = subHeadingTextAsArray.map(function (item, index) {
        return index === subHeadingTextAsArray.length - 1 ? item : __jsx(React.Fragment, null, item, __jsx("br", null));
      });
    }
  }
  return __jsx("div", {
    className: "nva-text-card ".concat(className),
    ref: TextCardContainer
  }, headingText && __jsx(Heading, _extends({
    HeadingType: headingType,
    className: "nva-text-card__header card-header"
  }, ContentfulLivePreview.getProps({
    entryId: entryId,
    fieldId: 'headline'
  })), cta && !isAlreadyWraped && styleType != 'heroCarousel' ? __jsx(Anchor, _extends({
    title: headingText,
    to: cta.url,
    hyperlinkType: cta.hyperlinkType,
    ctaBehavior: cta.behavior,
    "data-analytics-type": cta.anchorDataAnalyticsType,
    "data-analytics-value": cta.anchorDataAnalyticsValue,
    "data-analytics-variation": cta.anchorDataAnalyticsVariation,
    "aria-label": headingText
  }, others), headingText) : multiLineHeadline || headingText), subHeadline && __jsx(Heading, _extends({
    HeadingType: headingTypeSubHeading,
    className: "nva-text-card__sub-headline card-subHeading"
  }, ContentfulLivePreview.getProps({
    entryId: entryId,
    fieldId: 'subHeadline'
  })), subHeadline), subHeadingText && __jsx(Heading, _extends({
    HeadingType: headingTypeSubHeading,
    className: "nva-text-card__sub-heading-text card-subHeading"
  }, ContentfulLivePreview.getProps({
    entryId: entryId,
    fieldId: 'subHeadline'
  })), multiLineSubHeadline || subHeadingText), descriptionText && descriptionType && descriptionType === siteConstants.RICH_TEXT && (cta && !isAlreadyWraped && styleType != 'heroCarousel' ? __jsx(Anchor, _extends({
    title: cta.label || 'rich text card',
    to: cta.url,
    hyperlinkType: cta.hyperlinkType,
    ctaBehavior: cta.behavior,
    "data-analytics-type": cta.anchorDataAnalyticsType,
    "data-analytics-value": cta.anchorDataAnalyticsValue,
    "data-analytics-variation": cta.anchorDataAnalyticsVariation,
    "aria-label": headingText
  }, others), __jsx(RichText, {
    className: "nva-text-card__description card-rich-text",
    jsonContent: descriptionText,
    contentfulLivePreview: ContentfulLivePreview.getProps({
      entryId: entryId,
      fieldId: 'textBlock'
    })
  })) : __jsx(RichText, {
    className: "nva-text-card__description card-rich-text",
    jsonContent: descriptionText,
    isHero: isHero,
    shouldBeTabbable: shouldBeTabbable,
    contentfulLivePreview: ContentfulLivePreview.getProps({
      entryId: entryId,
      fieldId: 'textBlock'
    })
  })), descriptionText && !descriptionType && __jsx(Para, {
    className: "nva-text-card__description para-cta-desc-wrapper"
  }, cta && !isAlreadyWraped ? __jsx(Anchor, _extends({
    title: cta.label || descriptionText,
    to: cta.url,
    hyperlinkType: cta.hyperlinkType,
    ctaBehavior: cta.behavior,
    "data-analytics-type": cta.anchorDataAnalyticsType,
    "data-analytics-value": cta.anchorDataAnalyticsValue,
    "data-analytics-variation": cta.anchorDataAnalyticsVariation,
    "aria-label": headingText
  }, others, ContentfulLivePreview.getProps({
    entryId: entryId,
    fieldId: 'textBlock'
  })), descriptionText && descriptionText) : descriptionText ? descriptionText : ''), cta && !isAlreadyWraped && __jsx(Para, {
    className: "nva-text-card__cta para-cta-wrapper"
  }, __jsx(Anchor, _extends({
    tabIndex: shouldBeTabbable ? '0' : '-1',
    title: cta.label,
    to: cta.url,
    hyperlinkType: cta.hyperlinkType,
    styleType: cta.styleType,
    ctaBehavior: cta.behavior,
    className: styleType == 'heroCarousel' ? '' : 'arrow',
    "data-analytics-type": cta.anchorDataAnalyticsType,
    "data-analytics-value": cta.anchorDataAnalyticsValue,
    "data-analytics-variation": cta.anchorDataAnalyticsVariation,
    "aria-label": cta.label
  }, others), cta.label)), isAlreadyWraped && __jsx(Para, {
    className: "nva-text-card__cta para-cta-wrapper"
  }, __jsx(FakeAnchorStyled, {
    className: styleType == 'heroCarousel' ? '' : 'arrow'
  }, cta.label)));
};
TextCard.defaultProps = {
  className: '',
  styleType: '',
  headingType: 'h1',
  isAlreadyWraped: false
};
export default styled(TextCard).withConfig({
  componentId: "sc-5mwt0n-0"
})(["", ";"], styles);
export { TextCard as TextCardVanilla };