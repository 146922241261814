import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "ariaLabel", "headingText", "paraText", "paraTextClass", "phone", "phoneText", "fax", "faxText", "email", "emailText", "emergency", "emergencyText", "paraClassName", "addressText", "contactAddressObject", "dataAnalyticsAddress", "textPhone", "textPhoneText"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './ContactUsCard.style';
import Heading from '../../atoms/Heading';
import Para from '../../atoms/Para';
import List from '../../molecules/List';
import Anchor from '../../atoms/Anchor';
import ListItem from '../../molecules/List/ListItem';
// import { formatPhoneNumberHyphen } from '../../../utils/utilityFunction';
// import { formatPhoneNumberAria } from '../../../utils/utilityFunction';
import AddressCard from '../AddressCard';
import { convertPhoneFormat } from '../../../utils/utilityFunction';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';

var ContactUsCard = function ContactUsCard(_ref) {
  var className = _ref.className,
    ariaLabel = _ref.ariaLabel,
    headingText = _ref.headingText,
    paraText = _ref.paraText,
    paraTextClass = _ref.paraTextClass,
    phone = _ref.phone,
    phoneText = _ref.phoneText,
    fax = _ref.fax,
    faxText = _ref.faxText,
    email = _ref.email,
    emailText = _ref.emailText,
    emergency = _ref.emergency,
    emergencyText = _ref.emergencyText,
    paraClassName = _ref.paraClassName,
    addressText = _ref.addressText,
    contactAddressObject = _ref.contactAddressObject,
    dataAnalyticsAddress = _ref.dataAnalyticsAddress,
    textPhone = _ref.textPhone,
    textPhoneText = _ref.textPhoneText,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: "nva-contact-us-card ".concat(className)
  }, headingText && __jsx(Heading, {
    HeadingType: "h3",
    className: "nva-contact-us-card__heading"
  }, headingText), paraText && __jsx(Para, {
    className: "nva-contact-us-card__para ".concat(paraTextClass)
  }, paraText), __jsx(List, {
    className: "nva-contact-us-card__contact-details contact-details panel-list"
  }, __jsx(ListItem, {
    id: "phone-number",
    className: "nva-contact-us-card__list-item"
  }, phone && __jsx(React.Fragment, null, __jsx(Para, {
    className: "nva-contact-us-card__item-para ".concat(paraClassName, " para-line-height")
  }, phoneText), "\xA0", __jsx(Anchor, {
    title: 'phone ' + convertPhoneFormat(phone),
    "aria-label": 'phone ' + convertPhoneFormat(phone),
    to: "tel:".concat(convertPhoneFormat(phone)),
    className: "nva-contact-us-card__phone-anchor phone-link"
  }, __jsx(Para, {
    className: "nva-contact-us-card__phone-formatted para-line-height"
  }, convertPhoneFormat(phone))))), __jsx(ListItem, {
    id: "text-number",
    className: "nva-contact-us-card__list-item"
  }, textPhone && convertPhoneFormat(textPhone) && __jsx(React.Fragment, null, __jsx(Para, {
    className: "nva-contact-us-card__item-para ".concat(paraClassName, " para-line-height")
  }, textPhoneText), "\xA0", __jsx(Anchor, {
    title: 'phone ' + convertPhoneFormat(textPhone),
    "aria-label": 'phone ' + convertPhoneFormat(textPhone),
    to: "tel:".concat(convertPhoneFormat(textPhone)),
    className: "nva-contact-us-card__text-phone-anchor phone-link"
  }, __jsx(Para, {
    className: "nva-contact-us-card__text-phone-formatted para-line-height"
  }, convertPhoneFormat(textPhone))))), __jsx(ListItem, {
    id: "fax-number",
    className: "nva-contact-us-card__list-item"
  }, fax && fax != 'undefined' && __jsx(React.Fragment, null, __jsx(Para, {
    className: "nva-contact-us-card__item-para ".concat(paraClassName, " para-line-height")
  }, faxText), "\xA0", __jsx(Anchor, {
    title: 'fax ' + convertPhoneFormat(fax),
    "aria-label": 'fax ' + convertPhoneFormat(fax),
    to: "tel: ".concat(convertPhoneFormat(fax)),
    className: "nva-contact-us-card__fax-number-anchor phone-link"
  }, __jsx(Para, {
    className: "nva-contact-us-card__fax-number-formatted para-line-height"
  }, convertPhoneFormat(fax))))), email && __jsx(ListItem, {
    className: "nva-contact-us-card__list-item"
  }, __jsx(Para, {
    className: "nva-contact-us-card__item-para ".concat(paraClassName)
  }, emailText), "\xA0", __jsx(Para, {
    className: "nva-contact-us-card__email email"
  }, email)), emergency && __jsx(React.Fragment, null, __jsx(ListItem, {
    className: "nva-contact-us-card__list-item"
  }, __jsx(Para, {
    className: "nva-contact-us-card__message message"
  }, emergencyText)), __jsx(ListItem, {
    className: "nva-contact-us-card__list-item"
  }, __jsx(Para, {
    className: "nva-contact-us-card__emergency-phone"
  }, convertPhoneFormat(emergency)))), contactAddressObject && __jsx(ListItem, {
    className: "nva-contact-us-card__list-item"
  }, __jsx(Para, {
    className: "nva-contact-us-card__address ".concat(paraClassName)
  }, addressText), "\xA0", contactAddressObject && __jsx(AddressCard, _extends({
    "data-analytics-type": dataAnalyticsAddress && dataAnalyticsAddress.type ? dataAnalyticsAddress.type : '',
    "data-analytics-variation": dataAnalyticsAddress && dataAnalyticsAddress.variation ? dataAnalyticsAddress.variation : '',
    "data-analytics-value": dataAnalyticsAddress && dataAnalyticsAddress.value ? dataAnalyticsAddress.value : ''
  }, contactAddressObject, {
    className: "nva-contact-us-card__address-card address"
  })))));
};
ContactUsCard.defaultProps = {
  className: '',
  ariaLabel: '',
  headingText: ''
};
export default styled(ContactUsCard).withConfig({
  componentId: "sc-w7n4ml-0"
})(["", ";"], styles);
export { ContactUsCard as ContactUsCardVanilla };